<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';

const isOpen = defineModel<boolean>({ required: true });

interface Props {
  title?: string;
  size?:
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl'
    | '7xl'
    | '8xl'
    | '9xl';
  containerClass?: string;
}

const props = withDefaults(defineProps<Props>(), {
  title: undefined,
  size: 'md',
  containerClass: undefined,
});

const emit = defineEmits<{
  close: [];
}>();

const slots = defineSlots<{
  header(): void;
  default(): void;
  footer(): void;
}>();

const maxWidthClass = computed(() => `max-w-${props.size}`);
</script>

<template>
  <TransitionRoot as="template" :show="isOpen">
    <Dialog
      :unmount="true"
      class="relative z-[9999999999]"
      @close="
        isOpen = false;
        emit('close');
      "
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-semantic-background-secondary/80 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500"
              leave-from="translate-x-0"
              leave-to="translate-x-full"
            >
              <DialogPanel class="pointer-events-auto w-screen" :class="[maxWidthClass]">
                <div
                  class="flex h-full flex-col divide-y divide-semantic-border-primary bg-white shadow-2xl"
                >
                  <div class="px-4 py-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle>
                        <slot name="header">
                          <span v-if="title" class="text-lg font-medium">{{ title }}</span>
                        </slot>
                      </DialogTitle>
                      <div class="ml-3 flex h-7 items-center">
                        <button type="button" @click="isOpen = false">
                          <span class="sr-only">Close panel</span>
                          <IconifyIcon
                            class="text-2xl"
                            aria-hidden="true"
                            icon="heroicons:x-mark-solid"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    class="flex min-h-0 flex-1 flex-col overflow-y-auto px-4 py-6"
                    :class="[containerClass]"
                  >
                    <div class="relative flex-1">
                      <slot name="default">
                        <!-- Default content -->
                        <p>No content provided</p>
                      </slot>
                    </div>
                  </div>
                  <div v-if="!!slots.footer" class="flex shrink-0 justify-end p-4 py-6">
                    <slot name="footer"></slot>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
