<script setup lang="ts">
import { useField, type YupSchema } from 'vee-validate';

defineOptions({
  inheritAttrs: false,
});

interface Props {
  validations?: YupSchema;
  label?: string;
  containerClass?: string;
  hint?: string;
}

const attrs = useAttrs();
const props = defineProps<Props>();

const modelValue = defineModel<string>();

const slots = defineSlots<{
  leadingIcon?(): void;
  leading?(): void;
  trailingIcon?(): void;
  trailing?(): void;
}>();

const { errorMessage, setValue } = useField(attrs.name as string, props.validations, {
  initialValue: modelValue.value,
});

const inputName = computed(() => attrs.name as string | undefined);
</script>

<template>
  <div :class="containerClass">
    <label v-if="label" :for="inputName" class="mb-2 block">{{ label }}</label>
    <div
      class="group relative flex overflow-hidden rounded-md ring-1 ring-semantic-border-primary transition-all focus-within:ring-brand-primary"
      :class="{
        '!ring-semantic-border-error': errorMessage,
      }"
    >
      <div v-if="!!slots.leading" class="flex items-center">
        <slot name="leading" />
      </div>
      <div
        v-if="!!slots.leadingIcon"
        class="pointer-events-none flex items-center pl-3 text-semantic-text-secondary"
      >
        <slot name="leadingIcon" />
      </div>
      <input
        type="text"
        v-bind="attrs"
        v-model="modelValue"
        :id="inputName"
        @change="setValue(modelValue)"
        class="block w-full border-0 px-3 py-2 outline-none !ring-0 transition-all placeholder:text-semantic-text-secondary disabled:cursor-not-allowed disabled:bg-semantic-border-primary"
      />
      <div v-if="!!slots.trailing" class="flex items-center">
        <slot name="trailing" />
      </div>
      <div
        v-if="!!slots.trailingIcon"
        class="pointer-events-none flex items-center pr-3 text-semantic-text-secondary"
      >
        <slot name="trailingIcon" />
      </div>
    </div>
    <p v-if="hint" class="mt-2 text-sm text-semantic-text-secondary">
      {{ hint }}
    </p>
    <p v-if="errorMessage" class="mb-0 mt-2 flex items-center gap-1 text-sm text-red-600">
      <IconifyIcon class="size-4 text-inherit" icon="heroicons:exclamation-triangle" />
      {{ errorMessage }}
    </p>
  </div>
</template>

<style scoped lang="scss"></style>
