import type { i18nMessageSchema } from '@/plugins/i18n';
import { useI18n } from 'vue-i18n';

export const useTranslations = () => {
  const { t, locale } = useI18n<{ message: i18nMessageSchema }, 'en' | 'es' | 'pt'>();

  return {
    t,
    locale,
  };
};
