import { AxiosError } from 'axios';
import { toast } from '@/utils/toast.utils';

interface AppError {
  status: string;
  message: string;
  data?: unknown;
}

export function handleAxiosError(error: unknown, withToast = false): AppError {
  let appError: AppError = {
    status: 'UNKNOWN',
    message: 'An unknown error occurred',
  };

  if (error instanceof AxiosError) {
    const { response } = error;
    appError = {
      status: response?.status?.toString() ?? 'UNKNOWN',
      message: response?.data?.message ?? error.message,
      data: response?.data?.data,
    };
  } else if (error instanceof Error) {
    appError = {
      status: 'UNKNOWN',
      message: error.message || 'An unknown error occurred',
    };
  }

  if (withToast) {
    toast.error({
      title: 'An error occurred.',
      message: appError.message,
    });
  }

  return appError;
}
