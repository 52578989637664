<script setup lang="ts">
import { EcommerceSettingsPlanType } from '@/interfaces/ecommerce.interface';
import SmartIcon from '@/components/common/SmartIcon.vue';
import PrimaryButton from '@/components/common/PrimaryButton.vue';
import ModalDialog from '@/components/common/ModalDialog.vue';

const { planSubscriptionModalOpen, closePlanSubscriptionModal } = useUi();
const { currentEcommerce } = useCurrentEcommerce();
const { onCreatePlanSubscriptionApprovalHandler, pendingPlanSubscriptionApprovals } =
  usePlanSubscriptionApproval();
const { t } = useTranslations();

const form = reactive({
  current_plan_type:
    currentEcommerce.value?.settings.flokee_plan_type ?? EcommerceSettingsPlanType.TRIAL,
  new_plan_type: EcommerceSettingsPlanType.PRO,
});

const isLoading = ref(false);
const subscribeModalOpen = ref(false);
const thanksModalOpen = ref(false);

async function onSubmit() {
  isLoading.value = true;

  try {
    const { current_plan_type, new_plan_type } = form;
    await onCreatePlanSubscriptionApprovalHandler(
      { current_plan_type, new_plan_type },
      { silent: true },
    );

    subscribeModalOpen.value = false;
    thanksModalOpen.value = true;
  } catch {
    isLoading.value = false;
  }
}

function closeModal() {
  subscribeModalOpen.value = false;
  thanksModalOpen.value = false;
  closePlanSubscriptionModal();
}

watch(planSubscriptionModalOpen, (newVal) => {
  if (newVal) {
    if (pendingPlanSubscriptionApprovals.value?.length === 0) {
      subscribeModalOpen.value = true;
    } else {
      thanksModalOpen.value = true;
    }
  } else {
    closeModal();
  }
});
</script>

<template>
  <ModalDialog
    v-model="subscribeModalOpen"
    @close="
      subscribeModalOpen = false;
      if (!thanksModalOpen) closePlanSubscriptionModal();
    "
    size="xl"
  >
    <template #default>
      <h3
        class="mb-3 flex w-full items-center justify-center gap-2 text-center text-xl font-medium"
      >
        {{ t('plan_subscription_approval_drawer.title') }}
        <SmartIcon class="w-5" />
      </h3>
      <div class="flex flex-col gap-4">
        <p>
          {{ t('plan_subscription_approval_drawer.description') }}
        </p>
      </div>
    </template>
    <template #footer>
      <PrimaryButton
        @click="onSubmit"
        :isLoading="isLoading"
        class="mx-auto w-full max-w-sm"
        type="submit"
      >
        {{ t('plan_subscription_approval_drawer.button') }}
      </PrimaryButton>
    </template>
  </ModalDialog>

  <ModalDialog v-model="thanksModalOpen" @close="closeModal" size="lg">
    <template #default>
      <h3
        class="mb-3 flex w-full items-center justify-center gap-2 text-center text-xl font-medium"
      >
        {{ t('plan_subscription_approval_drawer.thanks.title') }}
        <SmartIcon class="w-5" />
      </h3>
      <div class="flex flex-col gap-4">
        <p>
          {{ t('plan_subscription_approval_drawer.thanks.description') }}
        </p>
      </div>
    </template>
    <template #footer>
      <PrimaryButton @click="closeModal" class="mx-auto" type="submit">
        {{ t('common.close') }}
      </PrimaryButton>
    </template>
  </ModalDialog>
</template>
