export enum PlanSubscriptionApprovalStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export interface PlanSubscriptionApproval {
  id: number;
  ecommerce_id: number;
  status: PlanSubscriptionApprovalStatus;
  current_plan_type: string;
  new_plan_type: string;
  notes?: string;
  updated_by: number;
  created_at: Date;
  updated_at: Date;
}
