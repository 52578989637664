import type { App } from 'vue';

interface IntercomSettings {
  api_base: string;
  app_id: string;
  user_id?: number;
  name?: string;
  email?: string;
  created_at?: Date;
}

declare global {
  interface Window {
    intercomSettings: IntercomSettings;
    Intercom: any;
  }
}

let intercomInstance: any = null;

export function createIntercom(options: IntercomSettings) {
  intercomInstance = {
    boot(userSettings: Partial<IntercomSettings> = {}) {
      window.intercomSettings = {
        ...options,
        ...userSettings,
      };

      (function () {
        const w = window;
        const ic = w.Intercom;
        if (typeof ic === 'function') {
          ic('reattach_activator');
          ic('update', w.intercomSettings);
        } else {
          const d = document;
          const i = function (...args: any[]) {
            (i as any).c(args);
          };
          (i as any).q = [];
          (i as any).c = function (args: any) {
            (i as any).q.push(args);
          };
          w.Intercom = i;
          const l = function () {
            const s = d.createElement('script');
            s.type = 'text/javascript';
            s.async = true;
            s.src = `https://widget.intercom.io/widget/${options.app_id}`;
            const x = d.getElementsByTagName('script')[0];
            x.parentNode?.insertBefore(s, x);
          };
          if (d.readyState === 'complete') {
            l();
          } else {
            w.addEventListener('load', l, false);
          }
        }
      })();
    },
    update(userSettings: Partial<IntercomSettings> = {}) {
      window.Intercom('update', userSettings);
    },
    shutdown() {
      window.Intercom('shutdown');
    },
  };
  return intercomInstance;
}

export function getIntercom() {
  return intercomInstance;
}

export default {
  install: (app: App, options: IntercomSettings) => {
    const intercom = createIntercom(options);
    app.config.globalProperties.$intercom = intercom;
  },
};
