<script setup lang="ts">
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';

interface Props {
  title?: string;
  containerClass?: string;
  contentClass?: string;
  footerClass?: string;
  size?:
    | 'xs'
    | 'sm'
    | 'md'
    | 'lg'
    | 'xl'
    | '2xl'
    | '3xl'
    | '4xl'
    | '5xl'
    | '6xl'
    | '7xl'
    | '8xl'
    | '9xl';
  static?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  containerClass: '',
  contentClass: '',
  footerClass: '',
  size: 'md',
  static: false,
});

const isOpen = defineModel<boolean>({ required: true });

const slots = defineSlots<{
  icon(): void;
  default(): void;
  footer(): void;
}>();

const emit = defineEmits<{
  close: [];
}>();

function closeModal() {
  isOpen.value = false;
  emit('close');
}

const maxWidthClass = computed(() => `max-w-${props.size}`);

const containerClass = computed(() => `${maxWidthClass.value} ${props.containerClass}`);
</script>

<template>
  <TransitionRoot as="template" @after-leave="emit('close')" :show="isOpen">
    <Dialog
      :static="static"
      :unmount="true"
      class="relative z-50"
      @close="static ? () => {} : (isOpen = false)"
    >
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-semantic-background-secondary/80 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-20 w-screen overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative w-full overflow-hidden rounded-xl bg-white p-8 text-left shadow-modal ring-1 ring-semantic-background-secondary transition-all"
              :class="containerClass"
            >
              <button
                v-if="!static"
                class="cursor-pointe absolute right-3 top-3 text-semantic-text-primary"
                @click="closeModal"
              >
                <IconifyIcon class="text-2xl" icon="heroicons:x-mark-solid" />
              </button>

              <div>
                <div v-if="!!slots.icon" class="mx-auto mb-5 flex items-center justify-center">
                  <slot name="icon" />
                </div>
                <div class="text-center">
                  <DialogTitle v-if="title" as="h3" class="mb-3 text-2xl leading-6">
                    {{ title }}
                  </DialogTitle>
                  <div :class="contentClass" class="">
                    <slot name="default" />
                  </div>
                </div>
              </div>
              <div :class="footerClass" v-if="!!slots.footer" class="mt-5">
                <slot name="footer" />
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
