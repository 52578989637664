import {
  ConfigurationTaskFilter,
  ConfigurationTaskStatus,
} from '@/interfaces/configurationTask.interface';
import { EcommerceSettingsPlanType } from '@/interfaces/ecommerce.interface';
import { PlanSubscriptionApprovalStatus } from '@/interfaces/planSubscriptionApproval.interface';
import { i18n } from '@/plugins/i18n';
import { flokService } from '@/services/flok.service';
import { useEcommerceStore } from '@/stores/ecommerce.store';
import { useUiStore } from '@/stores/ui.store';
import { toast } from '@/utils/toast.utils';
import type { NavigationGuard } from 'vue-router';

export const ecommerceMiddlewares: Record<string, NavigationGuard> = {
  isFlokeePro: async (to, from, next) => {
    const ecommerceStore = useEcommerceStore();
    const { t } = i18n.global as any;
    if (
      ecommerceStore.currentEcommerce?.settings.flokee_plan_type === EcommerceSettingsPlanType.PRO
    ) {
      return next();
    }

    toast.error({ message: t('notifications.must_purchase_flokee_pro') });
    next({ path: from.path });
  },
  isEcommerceSetup: async (_to, _, next) => {
    const ecommerceStore = useEcommerceStore();
    if (!ecommerceStore.currentEcommerce) return next();

    //Should be pro to apply the following validations
    if (ecommerceStore.currentEcommerce.settings.flokee_plan_type !== EcommerceSettingsPlanType.PRO)
      return next();

    //Should execute ads integration setup
    if (
      ecommerceStore.currentEcommerce.google_merchant_integration &&
      !ecommerceStore.currentEcommerce.google_ads_integration
    ) {
      await flokService.setupEcommerceGoogleAdsIntegration(ecommerceStore.currentEcommerce.id);
      const adsWizardConfigurationTask = ecommerceStore.configuration_tasks.find((t) =>
        t.name.includes(ConfigurationTaskFilter.Ads),
      );
      if (adsWizardConfigurationTask)
        await ecommerceStore.updateConfigurationTask(adsWizardConfigurationTask.id, {
          status: ConfigurationTaskStatus.DONE,
        });
    }

    next();
  },
  showPlanSubscriptionApprovalModal: async (to, from, next) => {
    const ecommerceStore = useEcommerceStore();
    await ecommerceStore.getPlanSubscriptionApprovals();

    if (
      ecommerceStore.plan_subscription_approvals.find(
        (psa) => psa.status === PlanSubscriptionApprovalStatus.APPROVED,
      )
    )
      return next();

    const uiStore = useUiStore();
    uiStore.openPlanSubscriptionModal();
    if (!from.name) return next({ name: 'DashboardView' });
    return false;
  },
};
