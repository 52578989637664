import { defineStore } from 'pinia';

interface UiState {
  sidebarOpen: boolean;
  supportDrawerOpen: boolean;
  isLoading: boolean;
  planSubscriptionModalOpen: boolean;
}

export const useUiStore = defineStore('ui', {
  state: (): UiState => ({
    sidebarOpen: false,
    supportDrawerOpen: false,
    planSubscriptionModalOpen: false,
    isLoading: false,
  }),
  getters: {},
  actions: {
    toggleSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    },
    toggleSupportDrawer() {
      this.supportDrawerOpen = !this.supportDrawerOpen;
    },
    closeSupportDrawer() {
      this.supportDrawerOpen = false;
    },
    openSupportDrawer() {
      this.supportDrawerOpen = true;
    },
    togglePlanSubscriptionModal() {
      this.planSubscriptionModalOpen = !this.planSubscriptionModalOpen;
    },
    closePlanSubscriptionModal() {
      this.planSubscriptionModalOpen = false;
    },
    openPlanSubscriptionModal() {
      this.planSubscriptionModalOpen = true;
    },
    setLoading(value: boolean) {
      this.isLoading = value;
    },
  },
});
