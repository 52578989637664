<script setup lang="ts">
import { useField, type YupSchema } from 'vee-validate';

defineOptions({
  inheritAttrs: false,
});

interface Props {
  validations?: YupSchema;
  label?: string;
}

const attrs = useAttrs();
const props = defineProps<Props>();

const modelValue = defineModel<string>();

const { errorMessage, setValue } = useField(attrs.name as string, props.validations, {
  initialValue: modelValue.value,
});

const inputName = computed(() => attrs.name as string | undefined);
</script>

<template>
  <div>
    <label v-if="label" :for="inputName" class="mb-2 block">{{ label }}</label>
    <textarea
      v-bind="attrs"
      v-model="modelValue"
      :id="inputName"
      @change="setValue(modelValue)"
      class="block min-h-32 w-full resize-none rounded-md border-0 px-3 py-2 ring-1 ring-semantic-border-primary transition-all placeholder:text-semantic-text-secondary focus:ring-brand-primary disabled:cursor-not-allowed disabled:bg-semantic-border-primary"
      :class="{ '!ring-semantic-border-error': errorMessage }"
    />
    <p v-if="errorMessage" class="mb-0 mt-2 flex items-center gap-1 text-sm text-red-600">
      <IconifyIcon class="size-4 text-inherit" icon="heroicons:exclamation-triangle" />
      {{ errorMessage }}
    </p>
  </div>
</template>

<style scoped lang="scss"></style>
