<script setup lang="ts">
import DrawerDialog from '../common/DrawerDialog.vue';
import PrimaryButton from '../common/PrimaryButton.vue';
import TextInput from '../form/TextInput.vue';
import { useForm } from 'vee-validate';
import * as yup from 'yup';
import { handleAxiosError } from '@/utils/error.utils';
import { toast } from '@/utils/toast.utils';
import { cxmService } from '@/services/cxm.service';
import TextareaInput from '../form/TextareaInput.vue';

const { closeSupportDrawer, supportDrawerOpen } = useUi();

const { t } = useTranslations();

const { handleSubmit } = useForm();

const form = reactive({
  subject: '',
  message: '',
});

const isLoading = ref(false);

const onSubmit = handleSubmit(async () => {
  isLoading.value = true;

  try {
    await cxmService.contactSupport(form);

    toast.success({ message: t('notifications.contact_support_success') });

    form.subject = '';
    form.message = '';

    closeSupportDrawer();
  } catch (error) {
    const { message } = handleAxiosError(error);
    toast.error({ message });
  } finally {
    isLoading.value = false;
  }
});
</script>

<template>
  <DrawerDialog
    @close="closeSupportDrawer()"
    v-model="supportDrawerOpen"
    size="lg"
    :title="t('support_drawer.customer_support')"
  >
    <h3 class="text-xl font-medium">{{ t('support_drawer.title') }}</h3>
    <p class="mt-2 text-semantic-text-secondary">
      {{ t('support_drawer.description') }}
    </p>
    <form @submit="onSubmit" class="mt-5 space-y-4">
      <div>
        <TextInput
          name="subject"
          :label="t('fields.labels.subject')"
          v-model="form.subject"
          :validations="yup.string().required(t('fields.validations.required'))"
        ></TextInput>
      </div>
      <div>
        <TextareaInput
          name="message"
          v-model="form.message"
          :label="t('fields.labels.message')"
          :validations="yup.string().required(t('fields.validations.required'))"
        />
      </div>

      <div>
        <PrimaryButton :isLoading="isLoading" class="w-full" type="submit">
          {{ t('support_drawer.form_button') }}
        </PrimaryButton>
      </div>
    </form>

    <hr class="my-5" />

    <div class="mt-5">
      <h3 class="mb-3 text-lg font-medium">{{ t('support_drawer.general_information') }}</h3>
      <ul role="list" class="divide-y divide-gray-100 rounded-md border border-gray-200">
        <li class="flex items-center justify-between px-4 py-2 text-sm leading-6">
          <div class="flex w-0 flex-1 items-center">
            <div class="flex min-w-0 flex-1 gap-2">
              <span class="truncate">{{ t('support_drawer.customer_support') }} (Argentina)</span>
            </div>
          </div>
          <div class="ml-4 shrink-0">
            <a
              href="tel:+54 911 51288641"
              class="flex items-center gap-1 font-medium text-brand-primary"
            >
              <IconifyIcon icon="heroicons:phone-16-solid" /> +54 911 51288641
            </a>
          </div>
        </li>
        <li class="flex items-center justify-between px-4 py-2 text-sm leading-6">
          <div class="flex w-0 flex-1 items-center">
            <div class="flex min-w-0 flex-1 gap-2">
              <span class="truncate"
                >{{ t('support_drawer.customer_support') }} (México y LATAM)</span
              >
            </div>
          </div>
          <div class="ml-4 shrink-0">
            <a
              href="tel:+52 055 9990 8060"
              class="flex items-center gap-1 font-medium text-brand-primary"
            >
              <IconifyIcon icon="heroicons:phone-16-solid" /> +52 (055) 9990 8060
            </a>
          </div>
        </li>
        <li class="flex items-center justify-between px-4 py-2 text-sm leading-6">
          <div class="flex w-0 flex-1 items-center">
            <div class="flex min-w-0 flex-1 gap-2">
              <span class="truncate">{{ t('support_drawer.customer_support') }} (USA)</span>
            </div>
          </div>
          <div class="ml-4 shrink-0">
            <a
              href="tel:+1 786 6000612"
              class="flex items-center gap-1 font-medium text-brand-primary"
            >
              <IconifyIcon icon="heroicons:phone-16-solid" /> +1 (786) 600-0612
            </a>
          </div>
        </li>
        <li class="flex items-center justify-between px-4 py-2 text-sm leading-6">
          <div class="flex w-0 flex-1 items-center">
            <div class="flex min-w-0 flex-1 gap-2">
              <span class="truncate">WhatsApp</span>
            </div>
          </div>
          <div class="ml-4 shrink-0">
            <a
              href="https://api.whatsapp.com/send?phone=5491152633802"
              target="_blank"
              class="flex items-center gap-1 font-medium text-brand-primary"
            >
              <IconifyIcon icon="ic:baseline-whatsapp" /> +54 911 5263 3802
            </a>
          </div>
        </li>
        <li class="flex items-center justify-between px-4 py-2 text-sm leading-6">
          <div class="flex w-0 flex-1 items-center">
            <div class="flex min-w-0 flex-1 gap-2">
              <span class="truncate">{{ t('common.email') }}</span>
            </div>
          </div>
          <div class="ml-4 shrink-0">
            <a
              href="mailto:info@engeni.com"
              class="flex items-center gap-1 font-medium text-brand-primary"
            >
              <IconifyIcon icon="heroicons:envelope" /> info@engeni.com
            </a>
          </div>
        </li>
      </ul>

      <ul role="list" class="mt-5 divide-y divide-gray-100 rounded-md border border-gray-200">
        <li class="flex items-center justify-between px-4 py-2 text-sm leading-6">
          <div class="flex w-0 flex-1 items-center">
            <div class="flex min-w-0 flex-1 gap-2">
              <span class="truncate">{{ t('common.website') }}</span>
            </div>
          </div>
          <div class="ml-4 shrink-0">
            <a
              href="https://engeni.com"
              target="_blank"
              class="flex items-center gap-1 font-medium text-brand-primary"
            >
              <IconifyIcon icon="heroicons:arrow-top-right-on-square" /> https://engeni.com
            </a>
          </div>
        </li>
        <li class="flex items-center justify-between px-4 py-2 text-sm leading-6">
          <div class="flex w-0 flex-1 items-center">
            <div class="flex min-w-0 flex-1 gap-2">
              <span class="truncate">{{ t('common.company_name') }}</span>
            </div>
          </div>
          <div class="ml-4 shrink-0">Engeni Argentina SRL</div>
        </li>
        <li class="flex items-center justify-between px-4 py-2 text-sm leading-6">
          <div class="flex w-0 flex-1 items-center">
            <div class="flex min-w-0 flex-1 gap-2">
              <span class="truncate">CUIT</span>
            </div>
          </div>
          <div class="ml-4 shrink-0">30717296954</div>
        </li>
      </ul>
    </div>
  </DrawerDialog>
</template>

<style scoped lang="scss"></style>
