import type { HandlerOptions } from '@/interfaces/common.interface';
import { PlanSubscriptionApprovalStatus } from '@/interfaces/planSubscriptionApproval.interface';
import type { CreatePlanSubscriptionApprovalDTO } from '@/services/flok.service.interface';
import { useEcommerceStore } from '@/stores/ecommerce.store';
import { handleAxiosError } from '@/utils/error.utils';
import { toast } from '@/utils/toast.utils';

export const usePlanSubscriptionApproval = () => {
  const { t } = useTranslations();
  const ecommerceStore = useEcommerceStore();

  const planSubscriptionApprovals = computed(() => ecommerceStore.plan_subscription_approvals);

  /**
   * Creates a plan subscription approval.
   * @param {CreatePlanSubscriptionApprovalDTO} data - The data for creating the plan subscription approval.
   * @param {Object} [options] - Optional parameters.
   * @param {boolean} [options.silent=true] - Whether to show toast notifications.
   * @returns {Promise<PlanSubscriptionApproval>} The created plan subscription approval.
   * @throws {Error} If the ecommerce is not set or if the API call fails.
   */
  async function onCreatePlanSubscriptionApprovalHandler(
    data: CreatePlanSubscriptionApprovalDTO,
    options: HandlerOptions = { silent: false },
  ) {
    const loadingToast = !options.silent
      ? toast.loading({
          message: t('notifications.create_plan_subscription_approval_loading'),
        })
      : null;
    try {
      await ecommerceStore.createPlanSubscriptionApproval(data);

      loadingToast?.resolve({
        message: t('notifications.create_plan_subscription_approval_success'),
      });
    } catch (error) {
      const { message } = handleAxiosError(error);
      loadingToast?.error({ message });

      throw new Error(message);
    }
  }

  return {
    //data
    planSubscriptionApprovals,
    pendingPlanSubscriptionApprovals: computed(() =>
      planSubscriptionApprovals.value?.filter(
        (a) => a.status === PlanSubscriptionApprovalStatus.PENDING,
      ),
    ),
    isApprovalRequestSent: computed(() => planSubscriptionApprovals.value?.length !== 0),
    isApprovalRequestPending: computed(() =>
      planSubscriptionApprovals.value?.some(
        (a) => a.status === PlanSubscriptionApprovalStatus.PENDING,
      ),
    ),
    isApprovalRequestApproved: computed(() =>
      planSubscriptionApprovals.value?.some(
        (a) => a.status === PlanSubscriptionApprovalStatus.APPROVED,
      ),
    ),
    isApprovalRequestRejected: computed(() =>
      planSubscriptionApprovals.value?.some(
        (a) => a.status === PlanSubscriptionApprovalStatus.REJECTED,
      ),
    ),
    //methods
    onCreatePlanSubscriptionApprovalHandler,
  };
};
