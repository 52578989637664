export default [
  {
    path: 'select-account',
    name: 'AdminSelectAccountView',
    component: () => import('@/modules/admin/views/AdminSelectAccountView.vue'),
  },
  {
    path: 'select-order',
    name: 'AdminSelectOrderView',
    component: () => import('@/modules/admin/views/AdminSelectOrderView.vue'),
  },
];
