import { authMiddlewares } from '@/middlewares/auth.middlewares';
import { ecommerceMiddlewares } from '@/middlewares/ecommerce.middlewares';
import { useUiStore } from '@/stores/ui.store';
import { createRouter, createWebHistory } from 'vue-router';
import adminRoutes from '@/modules/admin/routes/admin.routes';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: () => import('../layouts/DashboardLayout.vue'),
      beforeEnter: [
        authMiddlewares.isAuthenticated,
        authMiddlewares.isAccountConfigured,
        ecommerceMiddlewares.isEcommerceSetup,
      ],
      children: [
        {
          path: '',
          name: 'DashboardView',
          component: () => import('../views/common/DashboardView.vue'),
        },
        {
          path: 'products',
          name: 'ProductsView',
          component: () => import('../views/common/ProductsView.vue'),
        },
        {
          path: 'sales',
          name: 'SalesView',
          component: () => import('../views/common/SalesView.vue'),
        },
      ],
    },
    {
      path: '/user/settings',
      component: () => import('../layouts/UserSettingsLayout.vue'),
      beforeEnter: [
        authMiddlewares.isAuthenticated,
        authMiddlewares.isAccountConfigured,
        ecommerceMiddlewares.isEcommerceSetup,
      ],
      children: [
        {
          path: 'data',
          name: 'UserSettingsDataView',
          component: () => import('../views/user-settings/UserSettingsDataView.vue'),
        },
        {
          path: 'security',
          name: 'UserSettingsSecurityView',
          component: () => import('../views/user-settings/UserSettingsSecurityView.vue'),
        },
      ],
    },
    {
      path: '/account/settings',
      component: () => import('../layouts/AccountSettingsLayout.vue'),
      beforeEnter: [
        authMiddlewares.isAuthenticated,
        authMiddlewares.isAccountConfigured,
        ecommerceMiddlewares.isEcommerceSetup,
      ],
      children: [
        {
          path: 'general',
          name: 'AccountSettingsGeneralView',
          component: () => import('../views/account-settings/AccountSettingsGeneralView.vue'),
        },
        {
          path: 'users',
          name: 'AccountSettingsUsersView',
          component: () => import('../views/account-settings/AccountSettingsUsersView.vue'),
        },
        {
          path: 'billing',
          name: 'AccountSettingsBillingView',
          beforeEnter: [ecommerceMiddlewares.isFlokeePro],
          component: () => import('../views/account-settings/AccountSettingsBillingView.vue'),
        },
      ],
    },
    {
      path: '/store/settings',
      component: () => import('../layouts/StoreSettingsLayout.vue'),
      beforeEnter: [
        authMiddlewares.isAuthenticated,
        authMiddlewares.isAccountConfigured,
        ecommerceMiddlewares.isEcommerceSetup,
      ],
      redirect: '/store/settings/general',
      children: [
        {
          path: 'general',
          name: 'StoreSettingsGeneralView',
          component: () => import('../views/store-settings/StoreSettingsGeneralView.vue'),
        },
        {
          path: 'connections',
          name: 'StoreSettingsConnectionsView',
          component: () => import('../views/store-settings/StoreSettingsConnectionsView.vue'),
        },
        {
          path: 'notifications',
          name: 'StoreSettingsNotificationsView',
          component: () => import('../views/store-settings/StoreSettingsNotificationsView.vue'),
        },
        {
          path: 'billing',
          name: 'StoreSettingsBillingView',
          beforeEnter: [ecommerceMiddlewares.isFlokeePro],
          component: () => import('../views/store-settings/StoreSettingsBillingView.vue'),
        },
      ],
    },
    {
      path: '/store/connections',
      component: () => import('../layouts/BlankLayout.vue'),
      beforeEnter: [authMiddlewares.isAuthenticated, authMiddlewares.isAccountConfigured],
      redirect: '/store/settings/connections',
      children: [
        {
          path: 'tag-manager',
          beforeEnter: [ecommerceMiddlewares.isFlokeePro],
          children: [
            {
              path: '',
              name: 'StoreConnectionsTagManagerSetupView',
              component: () =>
                import(
                  '../views/store-connections/tag-manager/StoreConnectionsTagManagerSetupView.vue'
                ),
            },
            {
              path: 'internal',
              name: 'StoreConnectionsTagManagerInternalView',
              component: () =>
                import(
                  '../views/store-connections/tag-manager/StoreConnectionsTagManagerInternalView.vue'
                ),
            },
            {
              path: 'external',
              name: 'StoreConnectionsTagManagerExternalView',
              component: () =>
                import(
                  '../views/store-connections/tag-manager/StoreConnectionsTagManagerExternalView.vue'
                ),
            },
          ],
        },
        {
          path: 'analytics',
          children: [
            {
              path: '',
              name: 'StoreConnectionsAnalyticsSetupView',
              component: () =>
                import(
                  '../views/store-connections/analytics/StoreConnectionsAnalyticsSetupView.vue'
                ),
            },
            {
              path: 'internal',
              name: 'StoreConnectionsAnalyticsInternalView',
              component: () =>
                import(
                  '../views/store-connections/analytics/StoreConnectionsAnalyticsInternalView.vue'
                ),
            },
            {
              path: 'external',
              name: 'StoreConnectionsAnalyticsExternalView',
              component: () =>
                import(
                  '../views/store-connections/analytics/StoreConnectionsAnalyticsExternalView.vue'
                ),
            },
          ],
        },
        {
          path: 'merchant',
          beforeEnter: [ecommerceMiddlewares.isFlokeePro],
          children: [
            {
              path: '',
              name: 'StoreConnectionsMerchantSetupView',
              component: () =>
                import('../views/store-connections/merchant/StoreConnectionsMerchantSetupView.vue'),
            },
            {
              path: 'internal',
              name: 'StoreConnectionsMerchantInternalView',
              component: () =>
                import(
                  '../views/store-connections/merchant/StoreConnectionsMerchantInternalView.vue'
                ),
            },
            {
              path: 'external',
              name: 'StoreConnectionsMerchantExternalView',
              component: () =>
                import(
                  '../views/store-connections/merchant/StoreConnectionsMerchantExternalView.vue'
                ),
            },
          ],
        },
      ],
    },
    {
      path: '/auth',
      component: () => import('../layouts/BlankLayout.vue'),
      redirect: '/auth/login',
      children: [
        {
          path: 'login',
          name: 'AuthLoginView',
          beforeEnter: [authMiddlewares.shouldRedirectToDashboard],
          component: () => import('../views/auth/AuthLoginView.vue'),
        },
        {
          path: 'register',
          name: 'AuthRegisterView',
          beforeEnter: [authMiddlewares.shouldRedirectToDashboard],
          component: () => import('../views/auth/AuthRegisterView.vue'),
        },
        {
          path: 'email',
          name: 'AuthEmailView',
          beforeEnter: [authMiddlewares.shouldRedirectToDashboard],
          props: (route) => ({
            email: route.query.email ?? '',
          }),
          component: () => import('../views/auth/AuthEmailView.vue'),
        },
        {
          path: 'recover-password',
          component: () => import('../layouts/BaseLayout.vue'),
          children: [
            {
              path: '',
              name: 'AuthRecoverPasswordView',
              props: (route) => ({
                email: route.query.email ?? '',
              }),
              component: () => import('../views/auth/AuthRecoverPasswordView.vue'),
            },
          ],
        },
        {
          path: 'reset-password',
          component: () => import('../layouts/BaseLayout.vue'),
          children: [
            {
              path: '',
              name: 'AuthResetPasswordView',
              props: (route) => ({
                token: route.query.token ?? '',
              }),
              component: () => import('../views/auth/AuthResetPasswordView.vue'),
            },
          ],
        },
        {
          path: 'tienda-nube',
          component: () => import('../layouts/BaseLayout.vue'),
          beforeEnter: [authMiddlewares.verifyAuthentication],
          children: [
            {
              path: 'callback',
              name: 'AuthTiendanubeCallbackView',
              props: (route) => ({
                code: route.query.code ?? '',
              }),
              component: () => import('../views/auth/AuthTiendanubeCallbackView.vue'),
            },
          ],
        },
        {
          path: 'facebook',
          component: () => import('../layouts/BaseLayout.vue'),
          children: [
            {
              path: 'callback',
              name: 'AuthFacebookCallbackView',
              props: (route) => ({
                code: route.query.code ?? '',
              }),
              component: () => import('../views/auth/AuthFacebookCallbackView.vue'),
            },
          ],
        },
        {
          path: 'meli',
          component: () => import('../layouts/BaseLayout.vue'),
          children: [
            {
              path: 'callback',
              name: 'AuthMeliCallbackView',
              props: (route) => ({
                code: route.query.code ?? '',
              }),
              component: () => import('../views/auth/AuthMeliCallbackView.vue'),
            },
          ],
        },
      ],
    },
    {
      path: '/wizard',
      component: () => import('../layouts/BlankLayout.vue'),
      redirect: '/',
      beforeEnter: [authMiddlewares.isAuthenticated],
      children: [
        {
          path: 'create-account',
          name: 'WizardCreateAccountView',
          props: (route) => ({
            create: route.query.create,
          }),
          component: () => import('../views/wizard/WizardCreateAccountView.vue'),
        },
        {
          path: 'create-ecommerce',
          name: 'WizardCreateEcommerceView',
          component: () => import('../views/wizard/WizardCreateEcommerceView.vue'),
        },
        {
          path: 'select-account',
          name: 'WizardSelectAccountView',
          component: () => import('../views/wizard/WizardSelectAccountView.vue'),
        },
        {
          path: 'select-ecommerce-provider',
          name: 'WizardSelectEcommerceProviderView',
          component: () => import('../views/wizard/WizardSelectEcommerceProviderView.vue'),
        },
        {
          path: 'import-ecommerce',
          name: 'WizardImportEcommerceView',
          component: () => import('../views/wizard/WizardImportEcommerceView.vue'),
        },
      ],
    },
    {
      path: '/cart',
      component: () => import('../layouts/BlankLayout.vue'),
      redirect: '/cart/select',
      beforeEnter: [
        authMiddlewares.isAuthenticated,
        ecommerceMiddlewares.showPlanSubscriptionApprovalModal,
      ],
      children: [
        {
          path: 'select',
          name: 'CartSelectView',
          component: () => import('../views/cart/CartSelectView.vue'),
        },
        {
          path: 'checkout/:cart_id',
          name: 'CartCheckoutView',
          props: (route) => ({
            cart_id: Number(route.params.cart_id),
          }),
          component: () => import('../views/cart/CartCheckoutView.vue'),
        },
      ],
    },
    {
      path: '/test',
      component: () => import('../layouts/BaseLayout.vue'),
      beforeEnter: [authMiddlewares.verifyAuthentication],
      children: [
        {
          path: 'pusher',
          name: 'TestPusherView',
          component: () => import('../views/test/TestPusherView.vue'),
        },
      ],
    },
    {
      path: '/admin',
      component: () => import('../layouts/BaseLayout.vue'),
      beforeEnter: [authMiddlewares.verifyAuthentication, authMiddlewares.isAdmin],
      children: [...adminRoutes],
    },
    {
      path: '/:pathMatch(.*)*',
      component: () => import('../layouts/BaseLayout.vue'),
      beforeEnter: [authMiddlewares.verifyAuthentication],
      children: [
        {
          path: '',
          name: 'NotFoundView',
          component: () => import('../views/common/NotFoundView.vue'),
        },
      ],
    },
  ],
});

router.afterEach(() => {
  const uiStore = useUiStore();
  uiStore.setLoading(false);
});

router.onError((error, to) => {
  if (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed')
  ) {
    if (!to?.fullPath) {
      window.location.reload();
    } else {
      window.location.href = to.fullPath;
    }
  }
});

export default router;
