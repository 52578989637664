import { type NotivueItemProps } from '@/components/toast/BaseToast.vue';
import { push, type PushParameter } from 'notivue';

export const toast = {
  push,
  success(props: PushParameter<NotivueItemProps>) {
    push.success<NotivueItemProps>(props);
  },
  error(props: PushParameter<NotivueItemProps>) {
    push.error<NotivueItemProps>(props);
  },
  warning(props: PushParameter<NotivueItemProps>) {
    push.warning<NotivueItemProps>(props);
  },
  info(props: PushParameter<NotivueItemProps>) {
    push.info<NotivueItemProps>(props);
  },
  loading(props: PushParameter<NotivueItemProps>) {
    return push.promise<NotivueItemProps>(props);
  },
};
