export enum ConfigurationTaskStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  ERROR = 'ERROR',
  CANCELLED = 'CANCELLED',
}

export enum ConfigurationTaskFilter {
  TagManager = 'Tag Manager',
  Merchant = 'Merchant',
  Analytics = 'Analytics',
  Ads = 'Ads',
}

export interface ConfigurationTask {
  name: string;
  id: number;
  configuration_task_id: number;
  ecommerce_id: number;
  status: ConfigurationTaskStatus;
  updated_by?: Date;
  notes?: string;
  created_at: Date;
  updated_at: Date;
  order: number;
}
