import { EcommerceStatus } from '@/interfaces/ecommerce.interface';
import { useAuthStore } from '@/stores/auth.store';
import { useEcommerceStore } from '@/stores/ecommerce.store';
import { useUiStore } from '@/stores/ui.store';
import { handleAxiosError } from '@/utils/error.utils';
import { toast } from '@/utils/toast.utils';
import type { NavigationGuard } from 'vue-router';

export const authMiddlewares: Record<string, NavigationGuard> = {
  shouldRedirectToDashboard: async (_to, _, next) => {
    const authStore = useAuthStore();
    const uiStore = useUiStore();

    uiStore.setLoading(true);

    if (authStore.access_token) {
      uiStore.setLoading(false);
      return next({ name: 'DashboardView' });
    }

    uiStore.setLoading(false);

    next();
  },
  isAuthenticated: async (_to, _, next) => {
    const authStore = useAuthStore();
    const uiStore = useUiStore();

    uiStore.setLoading(true);
    if (!authStore.access_token) {
      uiStore.setLoading(false);
      return next({ name: 'AuthLoginView' });
    }

    if (!authStore.user) {
      try {
        await authStore.invalidate();
        await authStore.getCurrentUser();
      } catch (error) {
        localStorage.clear();
        authStore.access_token = undefined;
        const { message } = handleAxiosError(error);
        toast.error({ message });
        uiStore.setLoading(false);
        return next({ name: 'AuthLoginView' });
      }
    }

    uiStore.setLoading(false);

    next();
  },
  isAccountConfigured: async (_to, _, next) => {
    const authStore = useAuthStore();
    const ecommerceStore = useEcommerceStore();

    // User not logged in
    if (!authStore.user) {
      authStore.logout();
      return next({ name: 'AuthLoginView' });
    }

    // No current account
    if (!authStore.user.current_account_id) {
      if (authStore.user.role.id === 1 || authStore.user.role.id === 3) {
        return next({ name: 'AdminSelectAccountView' });
      }

      const hasAccounts = authStore.user.accounts?.length > 0;
      return next({ name: hasAccounts ? 'WizardSelectAccountView' : 'WizardCreateAccountView' });
    }

    // Current account not active
    if (authStore.currentAccount?.active !== 1) {
      const hasAccounts = authStore.user.accounts.length > 0;
      return next({ name: hasAccounts ? 'WizardSelectAccountView' : 'WizardCreateAccountView' });
    }

    // No current order or current order is not flokee
    if (
      !authStore.user.current_order_id ||
      (authStore.user.current_order && !authStore.user.current_order.tags.includes('flokee'))
    ) {
      if (authStore.user.role.id === 1 || authStore.user.role.id === 3) {
        return next({ name: 'AdminSelectOrderView' });
      }

      //check ecommerces
      const hasEcommerces = ecommerceStore.ecommerces.length > 0;
      if (!hasEcommerces) {
        return next({ name: 'WizardCreateEcommerceView' });
      }

      //Get first active ecommerce
      const activeEcommerce = ecommerceStore.ecommerces.find(
        (e) => e.status === EcommerceStatus.ACTIVE,
      );

      if (activeEcommerce) {
        //Set ecommerce/order to first ACTIVE ecommerce if exists
        await ecommerceStore.setSelectedEcommerce(activeEcommerce.id);
      } else {
        //Set ecommerce/order to first ANY ecommerce
        await ecommerceStore.setSelectedEcommerce(ecommerceStore.ecommerces[0].id);
      }
    }

    if (ecommerceStore.currentEcommerce && !ecommerceStore.currentEcommerce?.integration) {
      if (authStore.user.role.id === 1 || authStore.user.role.id === 3) {
        toast.error({ message: 'Ecommerce integration is not set' });
        return next({ name: 'AdminSelectAccountView' });
      }
      return next({ name: 'WizardSelectEcommerceProviderView' });
    }

    return next();
  },
  verifyAuthentication: async (_to, _, next) => {
    const authStore = useAuthStore();
    const uiStore = useUiStore();
    uiStore.setLoading(true);

    if (!authStore.user && authStore.access_token) {
      try {
        await authStore.getCurrentUser();
      } catch (error) {
        const { message } = handleAxiosError(error);
        toast.error({ message });
      }
    }

    uiStore.setLoading(false);

    next();
  },
  isAdmin: async (_to, _, next) => {
    const authStore = useAuthStore();

    if (!authStore.user && authStore.access_token) {
      return next({ name: 'AuthLoginView' });
    }

    if (authStore.user?.role.id !== 1 && authStore.user?.role.id !== 3) {
      return next({ name: 'AuthLoginView' });
    }

    next();
  },
};
