import { QueryClient, type VueQueryPluginOptions } from '@tanstack/vue-query';
import { type AsyncStorage } from '@tanstack/query-persist-client-core';
import { persistQueryClient } from '@tanstack/query-persist-client-core';
import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister';
import { get, set, del, createStore, type UseStore } from 'idb-keyval';

function newIdbStorage(idbStore: UseStore): AsyncStorage {
  return {
    getItem: async (key) => await get(key, idbStore),
    setItem: async (key, value) => {
      await set(key, value, idbStore);
    },
    removeItem: async (key) => {
      await del(key, idbStore);
    },
  };
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 1,
      staleTime: 1000 * 60 * 5,
    },
  },
});

export const vueQueryOptions = {
  enableDevtoolsV6Plugin: true,
  queryClient: queryClient,
  //TODO: Client persister is disabled for now
  /*clientPersister: (queryClient: QueryClient) => {
    return persistQueryClient({
      queryClient: queryClient as any,
      maxAge: 1000 * 60 * 60 * 1, // 1 hours
      dehydrateOptions: {
        shouldDehydrateQuery: (query) => {
          return (query.meta?.persist ?? false) as boolean;
        },
      },
      persister: createAsyncStoragePersister({
        storage: newIdbStorage(createStore('query', 'client')),
        throttleTime: 0,
      }),
    });
  },*/
} satisfies VueQueryPluginOptions;
