import { storeToRefs } from 'pinia';
import { useUiStore } from '@/stores/ui.store';

export function useUi() {
  const store = useUiStore();
  const { sidebarOpen, supportDrawerOpen, isLoading, planSubscriptionModalOpen } =
    storeToRefs(store);

  return {
    // Reactive state
    sidebarOpen,
    supportDrawerOpen,
    planSubscriptionModalOpen,
    isLoading,

    // Actions
    toggleSidebar: () => store.toggleSidebar(),
    toggleSupportDrawer: () => store.toggleSupportDrawer(),
    closeSupportDrawer: () => store.closeSupportDrawer(),
    openSupportDrawer: () => store.openSupportDrawer(),
    togglePlanSubscriptionModal: () => store.togglePlanSubscriptionModal(),
    closePlanSubscriptionModal: () => store.closePlanSubscriptionModal(),
    openPlanSubscriptionModal: () => store.openPlanSubscriptionModal(),
    setLoading: (value: boolean) => store.setLoading(value),
  };
}
