import { createNotivue } from 'notivue';

export const notivue = createNotivue({
  position: 'top-right',
  limit: 4,
  enqueue: true,
  notifications: {
    global: {
      duration: 4000,
    },
  },
  animations: {
    enter: 'slide-in-notification',
    leave: 'fade-out-notification',
    clearAll: 'fade-out-notificatio',
  },
});
